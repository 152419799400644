import * as React from "react";
import { graphql, withPrefix } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import { formatShortDate } from "../lib/event";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Button, Section, VideoContainer } from "../components/ui";
import { EventSummary } from "../components/event-summary";

import { events } from "../mock/events";

import IconGive from "../images/Icon_Give.svg";
import IconConnect from "../images/Icon_Connect.svg";
import IconMeet from "../images/Icon_Meet.svg";
import IconRules from "../images/Icon_Rules.svg";
import MapImage from "../images/Map.svg";
import TelegramIcon from "../images/Telegram_small.svg";
import LocationIcon from "../images/Location_Marker.svg";
import MailIcon from "../images/Mail-Icon.svg";
import PhoneIcon from "../images/Phone-Icon.svg";
import HelfaLogo from "../images/HelfaLogo.svg";
import HealthNewsImage from "../images/news1.png";
import KalNewsImage from "../images/news2.png";
import PhoneNewsImage from "../images/news3.png";

const news = [
  {
    id: 1,
    imageUrl: HealthNewsImage,
    headline: "Quo Vadis Pflege und Gesundheit: Was gibt es Neues?",
    date: "2022-05-15T00:00:00.000Z",
  },
  {
    id: 2,
    imageUrl: KalNewsImage,
    headline: "Kal auf Deutschland-Tour",
    date: "2022-05-10T00:00:00.000Z",
  },
  {
    id: 3,
    imageUrl: PhoneNewsImage,
    headline: "H.e.l.f.a. YouTube-Kanal",
    date: "2022-03-17T00:00:00.000Z",
  },
];

const heroStyle = {
  backgroundImage: `url(${withPrefix("/images/heroimg_u114.jpg")})`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `fill`,
  backgroundPosition: `bottom 35% center`,
};

const GuidelineItem = ({ icon, name, to }) => (
  <Link className="w-1/2 p-2 flex flex-col" to={to}>
    <img
      src={icon}
      className="p-4"
      alt={`Icon, das ${name} versinnbildlicht`}
    />
    <h3 className="light uppercase pt-3 text-lg lg:text-lg overflow-hidden">
      {name}
    </h3>
  </Link>
);

const Principle = ({ ordinal, children, to }) => (
  <Link className="border-1 border-gray-400 w-1/2 lg:w-1/4 h-32 my-4" to={to}>
    <p className="text-helfa-light-green text-5xl my-3 font-bold">{ordinal}</p>
    <div className="border-helfa-light-green w-4/5 mx-auto h-1 border-b" />
    <p className="my-3">{children}</p>
  </Link>
);

const LocationMarker = ({ name, top, left, chatUrl }) => (
  <a href={chatUrl} className={`text-center h-15 absolute ${top} ${left}`}>
    <img
      src={LocationIcon}
      className="w-6 text-center inline-block"
      alt="Location Icon"
    />
    <div className="text-xs pt-1">{name}</div>
  </a>
);

const NewsTeaser = ({ newsItem }) => (
  <div className="rounded-lg w-60 h-80 mr-4 text-left">
    <img
      src={newsItem.imageUrl}
      alt="Bild zur Neuigkeit"
      className="h-40 w-60 rounded-t-lg"
    ></img>
    <div className="h-40 w-60 bg-helfa-beige text-black p-4 rounded-b-lg">
      <div className="">{formatShortDate(newsItem.date)}</div>
      <h3 className="text-lg font-bold text-black text-left">
        {newsItem.headline}
      </h3>
    </div>
  </div>
);

const IndexPage = ({ location, data }) => {
  const { t } = useTranslation("index");
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Helfa Startseite" />
      <Section style={heroStyle} className="flex flex-col items-center">
        <div className="mb-20 lg:mb-32 p-5 md:p-8 lg:p-9 bg-white/50 inline-block rounded-full">
          <img
            src={HelfaLogo}
            className="h-32 md:h-60 lg:h-72"
            alt="Logo Helfa"
          ></img>
        </div>
        <div className="text-white ">
          <h1 className="text-xl md:text-3xl font-bold p-3 md:p-5">
            {t("H.e.l.f.a. Engagieren sich Leidenschaftlich Für Alle")}
          </h1>
          <p className="p-2 md:p-5 text-medium text-lg">
            {t(
              "H.e.l.f.a. ist ein Zusammenschluss von Menschen, die eine bessere Welt schaffen wollen, im besten Fall ohne den Einsatz von Geld und auf Basis des Schenkungsgedankens."
            )}
          </p>
          <Button to="/infos" className="text-white p-5">
            {t("Weitere Informationen")}
          </Button>
        </div>
      </Section>

      <Section backgroundColor="helfa-beige">
        <h2 className="text-2xl font-bold mb-5">
          {t("Gründer Kal im Interview")}
        </h2>
        <p className="my-3">
          {t(
            "Kal plaudert aus dem Nähkästchen und erzählt über seine Motivation, H.e.l.f.a. zu gründen und was daran besonders ist."
          )}
        </p>
        <VideoContainer />
        <p className="mb-3">
          {t("Du bist begeistert und möchtest dabei sein?")}
        </p>
        <Link to="/local-groups">
          <h3 className="text-xl font-bold">
            {t("Finde deine lokale Gruppe →")}
          </h3>
        </Link>
      </Section>

      <Section backgroundColor="helfa-light-green" color="white">
        <h2 className="text-2xl font-bold mb-5">{t("Die 4 Leitlinien")}</h2>
        <p className="mb-4">
          {t(
            "In einer Gemeinschaft, in der sich auch zukünftige Generationen ein sicheres, lebenswertes und substanzielles Leben aufbauen können, braucht es verlässliche Werte und Grundsätze."
          )}
        </p>
        <hr className="w-1/2 mx-auto mb-6" />
        <h3 className="text-xl font-bold">
          {t("Die Basis des H.e.l.f.a.-Prinzips")}
        </h3>
        <div className="flex justify-between flex-wrap mb-6 lg:mb-16 mt-6">
          <GuidelineItem icon={IconGive} name={t("Schenkungen")} to="/manual/#regel-1-schenken-kostenlos-oder-zum-selbstkostenpreis" />
          <GuidelineItem icon={IconMeet} name={t("Kennenlernen")} to="/manual/#regel-2-persönliches-kennenlernen-deanonymisieren" />
          <GuidelineItem icon={IconConnect} name={t("Vermittler")} to="/manual/#regel-3-moderatoren" />
          <GuidelineItem icon={IconRules} name={t("Vereinbarungen")} to="/manual/#regel-4-regeln" />
        </div>
        <Link to="/manual/#die-vier-helfa-grundregeln---4-plus-1">
          <h3 className="text-xl font-bold">{t("Mehr erfahren →")}</h3>
        </Link>
      </Section>

      <Section backgroundColor="helfa-beige">
        <h2 className="text-2xl font-bold mb-5">{t("Die 10 Prinzipien")}</h2>
        <p className="mb-4">
          {t(
            "Zusätzlich zu den 4 Leitlinien gibt es 10 Grundsätze, die wir hier vertreten, um eine Orientierung zu geben."
          )}
        </p>
        <div className="flex row flex-wrap space-between">
          <Principle ordinal="1" to="/manual/#1-bestrebung-der-menschheit">{t("Bestrebung der Menschheit")}</Principle>
          <Principle ordinal="2" to="/manual/#2-das-leben--kostbar-und-frei">{t("Das Leben - kostbar und frei")}</Principle>
          <Principle ordinal="3" to="/manual/#3-ressourcen">{t("Nutzung von Ressourcen")}</Principle>
          <Principle ordinal="4" to="/manual/#4-gleichberechtigung">
            {t("Freiheit und Gleichberechtigung")}
          </Principle>
          <Principle ordinal="5" to="/manual/#5-zusammenarbeit-mit-allem">{t("Zusammenarbeit mit allem")}</Principle>
          <Principle ordinal="6" to="/manual/#6-grundlagen-f%C3%BCrs-leben">{t("Grundlagen für das Leben")}</Principle>
          <Principle ordinal="7" to="/manual/#7-grenzen-der-natur">
            {t("Respekt vor Grenzen der Natur")}
          </Principle>
          <Principle ordinal="8" to="/manual/#8-l%C3%B6sungen-von-uns">{t("Lösungen für die Zukunft")}</Principle>
          <Principle ordinal="9" to="/manual/#9-versorgungspflicht-und-mitgef%C3%BChlspflicht">
            {t("Versorgungs- und Mitgefühlspflicht")}{" "}
          </Principle>
          <Principle ordinal="10" to="/manual/#10-verantwortung">
            {t("Verantwortung für Mensch und Tier")}
          </Principle>
        </div>
        <Link to="/manual/#10-prinzipien-die-hier-vertreten-werden">
          <h3 className="text-xl font-bold">{t("Mehr erfahren →")}</h3>
        </Link>
      </Section>

      <Section backgroundColor="helfa-light-green">
        <h2 className="text-white font-bold text-2xl mb-4">
          {t("Lokale Gruppen in den Großstädten von Deutschland")}
        </h2>
        <div className="flex flex-wrap justify-center">
          <div className="relative">
            <img src={MapImage} alt={t("Umrisskarte von Deutschland")} />

            <LocationMarker
              name="Berlin"
              top="top-[25%]"
              left="left-[75%]"
              chatUrl="https://t.me/joinchat/wfiOE4pxhdY0NzQy"
            />
            <LocationMarker
              name="Hamburg"
              top="top-[13.5%]"
              left="left-[40.5%]"
              chatUrl="https://t.me/joinchat/wfiOE4pxhdY0NzQy"
            />
            <LocationMarker
              name="Köln"
              top="top-[45.5%]"
              left="left-[9.5%]"
              chatUrl=""
            />
            <LocationMarker
              name="Leipzig"
              top="top-[42%]"
              left="left-[64%]"
              chatUrl=""
            />
          </div>
          <div className="text-white">
            <ul className="text-left">
              <li className="my-2">
                <a href="https://t.me/joinchat/wfiOE4pxhdY0NzQy">
                  <img
                    src={TelegramIcon}
                    alt="Telegram Icon"
                    className="inline-block mx-2"
                  />
                  Telegram-Gruppe Berlin Mitte
                </a>
              </li>
              <li className="my-2">
                <a href="https://t.me/joinchat/Usp-fKJdYp9jZTcy">
                  <img
                    src={TelegramIcon}
                    alt="Telegram Icon"
                    className="inline-block mx-2"
                  />
                  Telegram-Gruppe Hamburg Mitte
                </a>
              </li>
              <li className="my-2">
                <a href="https://t.me/+A0Y593MzRVQzOThi">
                  <img
                    src={TelegramIcon}
                    alt="Telegram Icon"
                    className="inline-block mx-2"
                  />
                  Telegram-Gruppe Köln Innenstadt
                </a>
              </li>
              <li className="my-2">
                <a href="https://t.me/+x5F_jfShbbc1MTQy">
                  <img
                    src={TelegramIcon}
                    alt="Telegram Icon"
                    className="inline-block mx-2"
                  />
                  Telegram-Gruppe Leipzig
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section backgroundColor="helfa-beige">
        <h2 className="text-2xl font-bold mb-5">
          {t("Anstehende Veranstaltungen")}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 mb-4">
          {events.map(event => (
            <EventSummary key={event.id} event={event} />
          ))}
        </div>
        <Link to="/events">
          <h3 className="text-xl font-bold">{t("Mehr erfahren →")}</h3>
        </Link>
      </Section>

      <Section backgroundColor="helfa-light-green" color="white">
        <h2 className="text-2xl font-bold mb-5">{t("Neuigkeiten")}</h2>
        <div className="flex basis-60 overflow-x-scroll overflow-y-hidden mb-4">
          <div className="flex basis-60 w-full h-full">
            {news.slice(0, 3).map(newsItem => (
              <NewsTeaser key={newsItem.id} newsItem={newsItem} />
            ))}
          </div>
        </div>
        <Link to="/news" className="mt-4">
          <h3 className="text-xl font-bold">{t("Mehr erfahren →")}</h3>
        </Link>
      </Section>

      <Section backgroundColor="helfa-beige">
        <h2 className="text-2xl font-bold mb-5">
          {t("Unser H.e.l.f.a. Deutschland Support hilft dir gerne weiter")}
        </h2>
        <div className="flex justify-center text-left flex-col sm:flex-row">
          <div className="p-3 flex items-center">
            <img src={PhoneIcon} className="h-10 mr-3" alt="Telefon Icon" />
            <div>
              <div className="text-xl font-bold">{t("Hotline")}</div>
              <div className="whitespace-nowrap">+49 2202 93 12 838</div>
            </div>
          </div>
          <div className="p-3 flex items-center">
            <img src={MailIcon} className="h-10 mr-4" alt="E-Mail Icon" />
            <div>
              <div className="text-xl font-bold">{t("E-Mail")}</div>
              <div>webmaster@helfa.org</div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  fragment SiteMetadata on Site {
    siteMetadata {
      title
      description
    }
  }

  fragment I18n on Locale {
    ns
    language
    data
  }

  query ($language: String!) {
    site {
      ...SiteMetadata
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
